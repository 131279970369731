import React from "react"
import AppointmentInfo from "../components/appointmentInfo"
import Calander from "../components/calander"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function BookAnAppointment() {
  return (
    <Layout>
      <SEO title="Book an Appointment" description="" />
      <Calander />
    </Layout>
  )
}
